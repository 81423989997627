<template>
  <div class="flex justify-center items-center w-full h-full">
    <div
      class="card flex flex-col justify-center h-full w-full sm:h-auto sm:w-96 p-4"
    >
      <div class="w-full flex justify-center items-center space-x-2 h-24">
        <i class="fas fa-spinner fa-spin fa-2x"></i>
        <span class="font-semibold">{{ profileName }}</span>
      </div>
      <div class="w-full">
        <div class="progress border border-gray-200 h-2 shadow-md rounded-full my-2">
          <div
            class="progress-bar rounded-full bg-blue-600 transition-all ease-out duration-3000"
            :class="loaderClasses"
          >
          </div>
        </div>
      </div>
      <div class="text-center">
        {{ $t('page.changeProfile.sentence') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, unref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useProfile } from '@/composables/profile/useProfile'
import { useUser } from '@/composables/user/useUser'
import { useMyPosts } from '@/api/publications/usePosts'

export default defineComponent({
  setup () {
    const router = useRouter()
    const redirectTime = 3000

    const {
      isLoaded: isUserLoaded
    } = useUser()

    const {
      isLoaded: isProfileLoaded,
      profile,
      isWizardCompleted,
      clearAnalytics,
    } = useProfile()

    const {
      clearMyPosts
    } = useMyPosts()

    const loaderClasses = ref(['w-0'])

    const profileName = computed(() => unref(profile).name)

    const redirectRouteName = computed(() => {
      if (!unref(isWizardCompleted)) {
        return 'wizard'
      }
      return 'home'
    })

    const redirect = () => {
      router.replace({ name: redirectRouteName.value })
    }

    watchEffect(() => {
      if (isUserLoaded.value && isProfileLoaded.value) {
        setTimeout(() => {
          redirect()
        }, redirectTime)
      }
    })

    onMounted(() => {
      clearMyPosts()
      clearAnalytics()
      setTimeout(() => {
        loaderClasses.value = ['w-full']
      }, 500)
    })

    return {
      profileName,
      loaderClasses
    }
  }
})
</script>

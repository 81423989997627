
import { computed, defineComponent, onMounted, ref, unref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useProfile } from '@/composables/profile/useProfile'
import { useUser } from '@/composables/user/useUser'
import { useMyPosts } from '@/api/publications/usePosts'

export default defineComponent({
  setup () {
    const router = useRouter()
    const redirectTime = 3000

    const {
      isLoaded: isUserLoaded
    } = useUser()

    const {
      isLoaded: isProfileLoaded,
      profile,
      isWizardCompleted,
      clearAnalytics,
    } = useProfile()

    const {
      clearMyPosts
    } = useMyPosts()

    const loaderClasses = ref(['w-0'])

    const profileName = computed(() => unref(profile).name)

    const redirectRouteName = computed(() => {
      if (!unref(isWizardCompleted)) {
        return 'wizard'
      }
      return 'home'
    })

    const redirect = () => {
      router.replace({ name: redirectRouteName.value })
    }

    watchEffect(() => {
      if (isUserLoaded.value && isProfileLoaded.value) {
        setTimeout(() => {
          redirect()
        }, redirectTime)
      }
    })

    onMounted(() => {
      clearMyPosts()
      clearAnalytics()
      setTimeout(() => {
        loaderClasses.value = ['w-full']
      }, 500)
    })

    return {
      profileName,
      loaderClasses
    }
  }
})
